import http from "./http"

/** @获取生成支付的二维码 {ordersId} */
export const $getWxPayCode = (params) =>  http({ method: 'post', url:'/Order/PayApi',params })

/** @退款  { ordersId,token,reason(原因)}*/
export const $apiWxRefundOrder = (params) =>  http({ method: 'post', url:'/Order/Refund',params }) 

/***
 * @钢品汇获取微信支付的二维码 {}
 */
export const $getDepositPayment = (params) => http({ method: 'post',url:'/deposit/payment', data:params})