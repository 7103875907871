const HelpRouters =[
  {
    path:"/help",
    meta: { title: '帮助说明' },
    component: () => import("@/view/help/index.vue"),
    children:[
      //规则
      {
        path: "/help/introduce",
        name: "Introduce",//平台介绍
        meta: { title: '平台介绍' },
        component: () => import("@/view/help/introduce/index.vue"),
      },
      {
        path: "/help/notice",
        name: "Notice",//平台公告
        meta: { title: '平台公告' },
        component: () => import("@/view/help/notice/index.vue"),
      },
      {
        path: "/help/notice/detail",
        name: "NoticeDetail",//公告详情
        meta: { title: '公告详情' },
        component: () => import("@/view/help/notice/Detail.vue"),
      },

      {
        path: "/help/general",
        name: "HelpGeneral",//总则
        meta: { title: '平台总则' },
        component: () => import("@/view/help/rule/General.vue"),
      },
      {
        path: "/help/inner/notice",
        name: "HelpInnerNotice",//内部通告
        meta: { title: '内部通告' },
        component: () => import("@/view/help/rule/InnerNotice.vue"),
      },
      {
        path: "/help/paystate",
        name: "HelpPayState",//付款说明
        meta: { title: '付款说明' },
        component: () => import("@/view/help/rule/PayState.vue"),
      },
      {
        path: "/help/agreement",
        name: "HelpAgreement",//用户协议
        meta: { title: '用户协议' },
        component: () => import("@/view/help/rule/Agreement.vue"),
      },
      {
        path: "/help/privacy",
        name: "HelpPrivacy",//隐私政策
        meta: { title: '隐私政策' },
        component: () => import("@/view/help/rule/Privacy.vue"),
      },

      //买家指南
      {
        path: "/help/howpay",
        name: "HelpHowPay",//如何付款
        meta: { title: '如何付款' },
        component: () => import("@/view/help/buyGuide/HowPay.vue"),
      },
      {
        path: "/help/redpacket",
        name: "HelpRedPacket",//新客红包
        meta: { title: '新客红包' },
        component: () => import("@/view/help/buyGuide/RedPacket.vue"),
      },
      {
        path: "/help/buy/lock",
        name: "HelpBuyLockPrice",//定金锁价
        meta: { title: '买家指南 | 定金锁价' },
        component: () => import("@/view/help/buyGuide/LockPrice.vue"),
      },
      {
        path: "/help/buy/pre/order",
        name: "HelpBuyPreOrder",//新客促销
        meta: { title: '买家指南 | 新客促销' },
        component: () => import("@/view/help/buyGuide/PreOrder.vue"),
      },
      // {
      //   path: "/help/usecoupon",
      //   name: "HelpUseCoupon",//如何用劵
      //   meta: { title: '如何用劵' },
      //   component: () => import("@/view/help/buyGuide/UseCoupon.vue"),
      // },

      //卖家指南
      {
        path: "/help/website",
        name: "HelpBuildWebsite",//建站服务
        meta: { title: '建站服务' },
        component: () => import("@/view/help/sellGuide/BuildWebsite.vue"),
      },
      {
        path: "/help/sellguide",
        name: "HelpSellGuide",//卖家指引
        meta: { title: '卖家指引' },
        component: () => import("@/view/help/sellGuide/SellGuide.vue"),
      },
      {
        path: "/help/sell/lock",
        name: "sellLock",//定金锁价
        meta: { title: '定金锁价' },
        component: () => import("@/view/help/sellGuide/LockPrice.vue"),
      },
      {
        path: "/help/sell/pre/order",
        name: "HelpSellPreOrder",//新客促销
        meta: { title: '卖家指南 | 新客促销' },
        component: () => import("@/view/help/sellGuide/PreOrder.vue"),
      },
      {
        path: "/help/track",
        name: "HelpOrderTrack",//订单跟踪
        meta: { title: '订单跟踪' },
        component: () => import("@/view/help/sellGuide/OrderTrack.vue"),
      },
      {
        path: "/help/entry",
        name: "HelpApplyEntry",//申请入驻
        meta: { title: '申请入驻' },
        component: () => import("@/view/help/sellGuide/ApplyEntry.vue"),
      },
      {
        path: "/help/track",
        name: "HelpOrderTrack",//订单跟踪
        meta: { title: '订单跟踪' },
        component: () => import("@/view/help/sellGuide/OrderTrack.vue"),
      },

      // 常见问题
      {
        path: "/help/withdrawal",
        name: "HelpWithdrawal",//如何提现
        meta: { title: '如何提现' },
        component: () => import("@/view/help/problem/Withdrawal.vue"),
      },
      {
        path: "/help/find",
        name: "HelpFindGoods",//如何找货
        meta: { title: '如何找货' },
        component: () => import("@/view/help/problem/FindGoods.vue"),
      },
      {
        path: "/help/login",
        name: "HelpLogin",//注册登录
        meta: { title: '注册登录' },
        component: () => import("@/view/help/problem/Login.vue"),
      },

      //售后服务
      {
        path: "/help/money",
        name: "HelpMoney",//质保金服务
        meta: { title: '质保金服务' },
        component: () => import("@/view/help/afterSale/Money.vue"),
      },
      
      {
        path: "/help/contact",
        name: "Contact",//联系我们
        meta: { title: '联系我们' },
        component: () => import("@/view/help/contact/index.vue"),
      },
    ],
  },
]
export default HelpRouters