import AppConfig from "@/config/app.config.js";
import EnterRoutes from '@/router/modules/EnterRoutes'
import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { RouterConfig } from '@/config/router.config.js';
import { $apiStoreList } from '@/api/modules/store';


async function InitRouter(app) {
    const unionList = []
    const storageUnion = localStorage.getItem("unionList")
    if (storageUnion && storageUnion !== 'undefined') {
        const data = JSON.parse(storageUnion);
        unionList.push(...data)
    } else {
        console.log(11);
        const { data,status } = await $apiStoreList();
        if(status){
            unionList.push(...data)
            localStorage.setItem("unionList", JSON.stringify(data))
        }
    }
    if (!unionList.length) { return mountRouter(app, EnterRoutes); }
    for (let i = 0; i < unionList.length; i++) {
        let obj = { id: unionList[i].company_id, path: unionList[i].url, name: unionList[i].name, alias: unionList[i].alias, icon: unionList[i].company?.logo,union:unionList[i].company?.affiliate_merchant?.merchant_type }
        EnterRoutes.push(...StoreRouterTemp(obj))
        if ((i + 1) == unionList.length) { mountRouter(app, EnterRoutes) }
    }
}
//是否展示该标题栏/ 根据传过来的参数判断是不是联盟商家
function isCheckShow (unionList,type){
    if(unionList){
        return type.some(item=>unionList.includes(item.toString()))
    }else {
        return true
    }
}

// 路由模板
function StoreRouterTemp(store) {
    return [
        {
            path: `/${store.path}/store/home`,
            meta: { title: `${store.name} | 首页`, alias: store.alias, info: store, socket: false, VTitle: "首页", hidden: true, sort: 1 },
            name: `${store.path}Home`,
            component: () => import("@/view/store/store-home/index.vue"),
        },
        {
            path: `/${store.path}/store/deposit`,
            meta: { title: `${store.name} | 定金锁价`, alias: store.alias, info: store, socket: false, VTitle: "定金锁价", hidden: isCheckShow(store.union,[2,5]), sort: 3 },
            name: `${store.path}Deposit`,
            component: () => import("@/view/store/store-deposit/index.vue"),
        },
        {
            path: `/${store.path}/pre/order`,
            meta: { title: `${store.name} | 新客促销`, alias: store.alias, info: store, socket: false, VTitle: "新客促销", hidden: isCheckShow(store.union,[2,3,5,6]), sort: 2 },
            name: `${store.path}PreOrder`,
            component: () => import("@/view/store/store-pre-order/index.vue"),
        },
        {
            path: `/${store.path}/steel`,
            meta: { title: `${store.name} | 钢制品订单`, alias: store.alias, info: store, socket: false, VTitle: "钢制品订单", hidden: isCheckShow(store.union,[4]), sort: 4 },
            name: `${store.path}Steel`,
            component: () => import("@/view/store/store-steel-product/index.vue"),
        },
        // {
        //     path: `/${store.path}/store/contract`,
        //     meta: { title: `${store.name} | 报价询价`, alias: store.alias, info: store, socket: false, VTitle: "报价询价", hidden: true },
        //     name: `${store.path}Contract`,
        //     component: () => import("@/view/store/store-contract/index.vue"),
        // },
        {
            path: `/${store.path}/store/goods`,
            meta: { title: `${store.name} | 现货资源`, alias: store.alias, info: store, socket: false, VTitle: "现货资源", hidden: true, sort: 5 },
            name: `${store.path}Goods`,
            component: () => import("@/view/store/store-goods/index.vue"),
        },
        // {
        //     path: `/${store.path}/store/enquiry`,
        //     meta: { title: `${store.name} | 在线询价`, alias: store.alias, info: store, socket: true, VTitle: "在线询价", hidden: true, sort: 6 },
        //     name: `${store.path}Enquiry`,
        //     component: () => import("@/view/store/store-enquiry/index.vue"),
        // },
        {
            path: `/${store.path}/store/about`,
            meta: { title: `${store.name} | 关于我们`, alias: store.alias, info: store, socket: false, VTitle: "关于我们", hidden: true, sort: 7 },
            name: `${store.path}About`,
            component: () => import("@/view/store/store-about/index.vue"),
        },

        {
            path: `/${store.path}/store/people`,
            meta: { title: `${store.name} | 业务员商品`, alias: store.alias, info: store, socket: false, VTitle: "业务员商品", hidden: false, sort: 8 },
            name: `${store.path}salesman`,
            component: () => import("@/view/store/salesman/index.vue"),
        },
        {
            path: `/${store.path}/store/goods/detail`,
            meta: { title: `${store.name} | 商品详情`, alias: store.alias, info: store, socket: false, VTitle: "商品详情", hidden: false, sort: 9 },
            name: `${store.path}GoodsDetail`,
            component: () => import("@/view/store/store-goods/goodsDetail.vue"),
        }
    ]
}
// 路由挂载
function mountRouter(app, EnterRoutes) {
    EnterRoutes.push({ path: "/:catchAll(.*)", redirect: "/404" })
    const router = createRouter({ history: createWebHistory(), routes: EnterRoutes });
    RouterConfig(router);
    new AppConfig(app, router);
    app.use(router).mount('#web');
}
export default InitRouter












