import { $apiUserInfo, $apiCompanyInfo,$getUserBalanceInfo,$apiEnquiryNum} from "@/api/modules/user";

import { ElMessage } from "element-plus";
const UserStore = {
  state: {
    userInfo:JSON.parse(localStorage.getItem("user_info")),
    companyInfo:{},
    balance:{},
    enquiryNum:0,
  },
  mutations: {
    setUserInfo(state, params) {
      state.userInfo = params.data
      if(params.data){localStorage.setItem("user_info",JSON.stringify(params.data))}
      params.reload&&params.reload()
    },
    setCompanyInfo(state, data) {
      state.companyInfo = data
    },
    setBlance(state, data) {
      state.balance = data
    },
    setEnquiryNum(state, data) {
      state.enquiryNum = data.Inquiry
    },
  },
  actions: {
    // 获取用户信息
    async getUserInfo(context,params) {
      const { status, data, msg } = await $apiUserInfo({ token:params.token })
      status ? context.commit("setUserInfo", {data:data,reload:params.reload}) : ElMessage.error(msg);
    },
    // 获取公司信息
    async getCompanyInfo(context,id) {
      const { status, data, msg } = await $apiCompanyInfo({id:id })
      status ? context.commit("setCompanyInfo", data) : ElMessage.error(msg);
    },
    // 获取余额
    async getBlance(context,token) {
      const { status, data, msg } = await $getUserBalanceInfo({token })
      status ? context.commit("setBlance", data) : ElMessage.error(msg);
    },
    // 获取询价信息数量
    async getEnquiryNum(context,token) {
      const { status, data, msg } = await $apiEnquiryNum({token })
      status ? context.commit("setEnquiryNum", data) : console.log(msg);
    },
  }
}
export default UserStore