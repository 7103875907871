import http from "./http"

/** 
  @name { VIP价格申请列表 } 
  @params { token } 
*/
export const $apiVipApplyList = (data) =>http({ method: 'post', url:'/vip/apply/list',params: data }) 
// 获取vip微信支付二维码 params:token,type_id 1体验型vip，2标准
export const $getWxPayCode = (data) =>http({ method: 'post', url:'/create/vip/order',data: data }) 
// VIP补贴数据返回接口 params:token
export const $getVipRebateData = (data) =>http({ method: 'post', url:'/user/vip/rebate/message',data: data })


/** 
  @name { 购买vip } 
*/
// 不同vip类型的信息
export const $vipListInfo = (data) =>http({ method: 'get', url:'/user/apply/list',data: data }) 
// 购买vip申请 params:token,typeId（开通vip类型 2体验VIP 3标准VIP 4高级VIP 5超级VIP） ，type（支付方式 H5 或者App）
export const $vipApply = (data) =>http({ method: 'post', url:'/user/apply/addMember',data: data }) 


