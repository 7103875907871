import CryptoJS from 'crypto-js'

// 解密数据
export function decrypt(encryptedVal) {
  let key = "a4b3c6d6e3f2";
  let iv = "12a34b56c78d90ef";
  let encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedVal);
  let src = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
  const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
  let decrypt = CryptoJS.AES.decrypt(src, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
// 解密数据
export function encrypt(data) {
  let key = "a4b3c6d6e3f2";
  let iv = "12a34b56c78d90ef";
  // 统一将传入的字符串转成UTF8编码
  const dataHex = CryptoJS.enc.Utf8.parse(data) // 需要加密的数据
  const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
  const ivHex = CryptoJS.enc.Utf8.parse(iv) // 偏移量
  const encrypted = CryptoJS.AES.encrypt(dataHex, keyHex, {
    iv: ivHex,
    mode: CryptoJS.mode.CBC, // 加密模式
    padding: CryptoJS.pad.Pkcs7
  })
  let encryptedVal = encrypted.ciphertext.toString()
  return encryptedVal //  返回加密后的值
}

