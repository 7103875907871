import StoreRoutes from "./StoreRoutes";
import SalesRoutes from "./SalesRoutes";
import StewardRoutes from "./StewardRoutes";
import PaymentRoutes from "./PaymentRoutes";
import UserRoutes from "./UserRoutes";

import OtherRoutes from "./OtherRoutes"
import DealRoutes from "./DealRoutes"

const isSales = process.env.VUE_APP_PATH == '/sales/home'

const root = isSales ? { path: '/' , redirect:'/sales/home', } : { path: "/deal", redirect: "/", }

const EnterRoutes = [
  root,
  ...OtherRoutes,
  ...DealRoutes,
  ...StoreRoutes,
  ...SalesRoutes,
  ...StewardRoutes,
  ...PaymentRoutes,
  ...UserRoutes,
];


export default EnterRoutes;