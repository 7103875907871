import { ElMessageBox, ElMessage } from 'element-plus'
import * as filters from "@/filters/EnterFilters.js"
import Clipboard from 'clipboard'


class AppConfig {
  constructor(app,router){
    this.router = router;
    this.initConfig(app)
    this.initProvide(app)
    this.warnHandler(app)
  }
  initConfig(app){
    app.config.globalProperties.$copy = this.copy
    app.config.globalProperties.$filters = filters
    app.config.globalProperties.$msg = this.initElMessage()
  }
  initProvide(app){
    app.provide('$toast', ElMessage)
    app.provide('$toastBox', ElMessageBox)
    app.provide('$RouterOpen', this.RouterOpen())
    app.provide('$cdnImg', "http://cdn.gangxinbao.cn/")
  }
  initElMessage(){
    return {
      success:(msg)=>ElMessage.success({message:String(msg),showClose: true,}),
      error:(msg)=>ElMessage.error({message:String(msg),showClose: true,}),
      warn:(msg)=>ElMessage.warning({message:String(msg),showClose: true,}),
      confirm:(msg,title,btn={yes:"确定",no:"取消"},options)=>
      ElMessageBox.confirm(msg,title,{ confirmButtonText:btn.yes || '确定', cancelButtonText: btn.no || '取消',...options })
    }
  }
  // 打开新窗口
  RouterOpen(){
    const router = this.router
    return function (path,params){
      let route = router.resolve({ path:path, query: params});
      window.open(route.href, '_blank');
    }
  }
  // 屏蔽警告信息
  warnHandler(app){
    app.config.warnHandler = () => null;
  }
  // 屏蔽错误信息
  errorHandler(app){
    app.config.errorHandler = () => null;
  }
  copy(name) {
    const clipboard = new Clipboard(name)  
    clipboard.on('success', e => {  
      ElMessage.success({message:'复制成功'});
      clipboard.destroy()  
      console.log(e);
    })  
    clipboard.on('error', e => {  
      ElMessage.error({message:'该浏览器不支持自动复制'})  
      clipboard.destroy()  
    })  
    
  }
}
export default AppConfig