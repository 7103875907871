import http from "./http"
const baseUrl = "https://pay.gxb.cn/api"

// 14家商家列表
export const $apiStoreList = () => http({ method: "get", url: "/company/short/link"});
// 联盟商家商铺信息
export const $apiUnionInfo = (data) => http({method: "post",url: baseUrl+"/shop/about",data,})
// 联盟商家用户信息列表
export const $apiUnionUserList = (data) => http({method: "post",url: baseUrl+"/merchants/user/list",data,})

//公司信息
export const $apiCompanyInfo = (data) => http({ method: "post", url: "/company/info",data});
// 钢材分类
export const $apiSteelTypeList = (data) => http({ method: "post", url: "/type/main", params: data, });
// 秒杀区
export const $apiSecKillGoods = (data) => http({ method: "post", url: "/home/seckill", params: data })
// 定金区
export const $apiDepositGoods = (data) => http({ method: "post", url: "/home/deposit", params: data })
// 合同区
export const $apiContractGoods = (data) => http({ method: "post", url: "/home/goods/recommend", params: data })
// home推荐
export const $apiRecommendGoods = (data) => http({ method: "post", url: "/home/goods/list", params: data })
// home业务员 @param：companyId
export const $apiSalesman = (data) => http({ method: "post", url: "/pay/company/user/list", params: data })
export const $apiNewSalesman = (data) => http({ method: "post", url: baseUrl+"/company/user/list", data })
// 业务员商品列表 @user_id
export const $apiSalesmanList = (data) => http({ method: "post", url: "/sales/goods", params: data })
// home在售商品 @params：companyId
export const $apiSaleShop = (data) => http({ method: "get", url: "/web/company/quantity/sale", params: data })
// home询价列表 @params：companyId
export const $apiEnquiryList = (data) => http({ method: "get", url: "/Goods/inquiry/list", params: data })


/** @name:{商品筛选条件组}  @param {type, page, typeId,name,texture,spec,steelMills,companyId  } */
export const $apiGoodsList = (data) => http({ method: "post", url: "/seckill/goods/list", params: data })

/** @name:{获取所在店铺购物车数量} */
export const $apiShopCarCount = (data) => http({ method: "get", url: "/shopping/amount", params: data })

/** @name:{获取所在店铺购物车数量}  @param {companyId} */
export const $apiStoreBanner = (data) => http({ method: "post", url: "/company/banner", params: data })


// 商城钢材分类
export const $apiStoreType = (data) => http({ method: "post", url: "/new/type/main", params: data, });