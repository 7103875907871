import http from "./http"
import axios from "axios"
const newUrl = 'https://pay.gxb.cn/api'
// 平台公告 params:limit,info,start,end,page
export const $getNoticeList = (data) =>  axios({ method: 'post', url:'https://api.bxgb2b.com/api/plat/notice',params: data })
// 公告详情 params:type:公告6，
export const $getNoticeDetail = (data) =>  axios({ method: 'post', url:'https://api.bxgb2b.com/api/content/info',params: data })

// 定金锁价公告
export const $getQuoteNotice = (data) =>  http({ method: 'get', url:'/Order/Lock/notice',params: data })
// 行情信息
export const $getQuoteInfo = (data) =>  http({ method: 'get', url:'/order/news/float',params: data })

// 订单交易信息
/*
params:type 0全部、1定金锁价、2预定促销
data:
volume_yesterday 昨日成交量
Transaction_amount_yesterday 昨日成交金额
Tons_traded_yesterday 昨日成交吨数
Total_trading_volume 总成交量
Total_transaction_tonnage 总成交吨数
Total_transaction_amount 总成交金额
*/
export const $getOrderSituation = (data) =>  http({ method: 'get', url:'/goods/statistics/message',params: data })


// 品种筛选列表 type:1卖货，2采购
export const $getTypeScreenList = (data) =>  http({ method: 'get', url:'/Order/Lock/label',params: data })
// 采购区列表 params：page，price_level（价格排序：1正序，2倒序），time_level（时间排序：1正序，2倒序）
export const $getPurchaseList = (data) =>  http({ method: 'post', url:'/order/Web/stock',params: data })
// 卖货区列表 params：page，price_level（价格排序：1正序，2倒序），time_level（时间排序：1正序，2倒序）
export const $getSaleList = (data) =>  http({ method: 'post', url:'/order/Web/sellStock',params: data })
// 定金 品名列表 
export const $getGoodsName = (data) =>  http({ method: 'get', url:'/total/types/list',params: data })
// 用户添加采购单 params：phone,code,token,list
export const $addPurchaseOrder = (data) =>  http({ method: 'post', url:'/order/stock/goods',params: data })
// 商家添加卖货单 params：phone,code,token,list
export const $addSaleOrder = (data) =>  http({ method: 'post', url:'/order/stock/goods/bloc',params: data })
// 我要买下 params：token,list[{goodsid:''}]
export const $buyOrder = (data) =>  http({ method: 'post', url:'/Order/Order',params: data })
// 我要接单 params：token,id
export const $takeOrder = (data) =>  http({ method: 'post', url:'/order/stock/detriment',params: data })
// 卖货单单品修改数量 params：token,,type:1(加入购物车),订单id,修改number
export const $changeShopNum = (data) =>  http({ method: 'post', url:'/shoppingCartUp',params: data })

// 用户的采购单列表 params：token,page,state 
export const $getUserPurchaseOrder = (data) =>  http({ method: 'get', url:'/order/user/publish',params: data })
// 用户的发布列表 params：token,page,state 1.买货，2.卖货
export const $getUserRelease = (data) =>  http({ method: 'get', url:'/user/appPub',params: data })
// 用户的交易奖励列表 token
export const $getUserReward = (data) =>  http({ method: 'post', url:'/Order/new/reward',params: data })
// 用户改价 params：token,list:goodsId,price
export const $changeOrderPrice = (data) =>  http({ method: 'post', url:'/order/stock/edit',params: data })

// 锁价订单 params：token,page
export const $lockOrderList = (data) =>  http({ method: 'post', url:'/user/order/earnest/news',data: data })
// 锁价订单流水 params：token,page
export const $getLockBillList = (data) =>  http({ method: 'get', url:'/order/deposit/flow',params: data })
// 定金余额 params：token
export const $getDepositMoney = (data) =>  http({ method: 'get', url:'/order/user/money',params: data })

// 上传货款凭证  token images[] txn_seqno合同编号
export const $apiUploadPayProof = (data) =>  http({ method: 'post', url:'/Order/payment/proof',data: data })
// 上传提货单  token images[] txn_seqno合同编号
export const $apiUploadProof = (data) =>  http({ method: 'post', url:'/Order/review/submit',data: data })
// 上传收货单  token images[] txn_seqno合同编号
export const $apiUploadReceiveProof = (data) =>  http({ method: 'post', url:'/Order/review/receipt',data: data })
