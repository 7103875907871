import axios from "axios";
import { $ROUTER } from '@/config/router.config';

// axios.js文件配置
// 自定义实例默认值
const axiosInstance = axios.create({
  //  https://gangxinbao.cn/api/ http://new.gxb.cn/api/
  baseURL: "https://gangxinbao.cn/api/", // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
  timeout: 20000, // 如果请求花费了超过 `timeout` 的时间，请求将被中断
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data.status == -1) {
      return $ROUTER("/");
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
