//订货系列接口

import http from "./http"

//订货单

// 定金订货已完成的列表滚动
export const $orderfloat = (data) =>  http({ method: 'get', url:'/user/news/float',params: data })
// 发布定金订货
export const $publishDepositOrder = (data) =>  http({ method: 'post', url:'/user/publish/product',params: data })
// 获取定金订货列表
export const $getDepositOrderList = (data) =>  http({ method: 'post', url:'/user/publish/list',params: data })
// 获取定金订货列表详情
export const $getDepositOrderDetail = (data) =>  http({ method: 'post', url:'/user/publish/info',params: data })
// 下单购买
export const $buyDepositOrderGoods = (data) =>  http({ method: 'post', url:'/user/publish/BuyProducts',params: data })
// 发布的订货列表
export const $publishOrderList = (data) =>  http({ method: 'get', url:'/user/publish/orderCargo',params: data })
// 订货单的列表
export const $userDepositOrderList = (data) =>  http({ method: 'get', url:'/user/order/list',params: data })
// 撤销发布
export const $revocationOrder = (data) =>  http({ method: 'post', url:'/user/revocation/order',params: data })
// 修改订单
export const $changeOrder = (data) =>  http({ method: 'post', url:'/user/modify/order',params: data })


//预订单

// 预定卖货列表
export const $preSaleOrderList = (data) =>  http({ method: 'post', url:'/user/publish/saleList',params: data })
// 发布预定采购
export const $publishPreBuyOrder = (data) =>  http({ method: 'post', url:'/user/news/product',params: data })
// 发布预定卖货
export const $publishPreSaleOrder = (data) =>  http({ method: 'post', url:'/user/news/sales',params: data })
// 用户已发布预定促销的订单
export const $userPublishPreOrder = (data) =>  http({ method: 'get', url:'/user/publish/productList',params: data })
// 用户已生成的预定促销的订单
export const $userPreOrder = (data) =>  http({ method: 'get', url:'/user/order/list',params: data })

