import { createApp } from 'vue';
import { Toast } from 'vant';
import 'element-plus/dist/index.css';
import 'vant/lib/index.css';
import '@/css/commonStyle.scss';
import '@/css/van.scss';
import '@/css/el.scss';
import store from "@/store/index"
import '@/assets/iconfont/iconfont.css';
import ElementPlus from 'element-plus'; 
import InitRouter from '@/router/InitRouter'; 
import locale from 'element-plus/lib/locale/lang/zh-cn';
import App from './App.vue'
import print from 'vue3-print-nb'
const app = createApp(App); 
app.use(store).use(ElementPlus, { locale }).use(Toast).use(print);
InitRouter(app)
