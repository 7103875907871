import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-369546cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bg"
}
const _hoisted_2 = { class: "identity" }
const _hoisted_3 = { class: "identity_select" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "select flex_r" }

import { Select } from '@element-plus/icons-vue';
import { ref } from 'vue';
import {$apiSelectIdentity} from '@/api/modules/user'
import { ElMessage } from 'element-plus';
import { useStore } from "vuex";

export default {
  setup(__props) {

const store = useStore()
const token = localStorage.getItem('token')
const userInfo = JSON.parse(localStorage.getItem('user_info'))
const selectList = [
  {id:2,title:'我是卖家'},
  {id:1,title:'我是买家'},
]
const selectType = ref(userInfo?.merchant_type?.length ? 2 : 1)
const isShow = token&&!userInfo.role_id
const showModel = ref(isShow)
// 确认操作
const confirmHandler = async()=>{
  const {data,status,msg} = await $apiSelectIdentity({token,role_id:selectType.value})
  if(status){
    ElMessage.success('选择成功')
    store.dispatch('getUserInfo', { token, reload: (() => { }) })
    showModel.value = false
  }else{
    ElMessage.warning(msg)
  }
}


return (_ctx, _cache) => {
  const _component_el_icon = _resolveComponent("el-icon")

  return (showModel.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(selectList, (item) => {
              return _createElementVNode("li", {
                key: item.id,
                onClick: $event => (selectType.value = item.id),
                class: _normalizeClass(['type'+item.id,selectType.value === item.id?('active'+item.id):null])
              }, [
                _createElementVNode("img", {
                  src: require('@/assets/deal/identity'+item.id+'.png'),
                  alt: ""
                }, null, 8, _hoisted_5),
                _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_7, [
                  (selectType.value === item.id)
                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Select))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ], 10, _hoisted_4)
            }), 64))
          ]),
          _createElementVNode("button", {
            class: "btn",
            onClick: confirmHandler
          }, "我选好了")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

}