import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, getCurrentInstance} from 'vue'
import {useImageHandler} from "@/hooks/index.js"

export default {
  props: {
  acceptList:Array
},
  emits: ['success'],
  setup(__props, { emit: $emits }) {

const props = __props

const {proxy:{$msg}} = getCurrentInstance()
const { fileToBase64,compressImage } = useImageHandler;


const InputRef = ref()
const upload_bool = ref(true)
async function uploadChange(e){
  if (!upload_bool.value) return $msg.error("请勿频繁点击");
  upload_bool.value = false
  let file = e.target.files[0];
  console.log(file);
  let newAcceptList = []
  if(props.acceptList){
    props.acceptList.forEach(item => {
      newAcceptList.push('image/'+item)
    });
  }
  console.log(file.type);
  if(newAcceptList.length&&!newAcceptList.includes(file.type)) return $msg.error(`请上传${props.acceptList.toString()}的图片`);

  const img = await fileToBase64(file);
  file.size>50000?compressImage(img.src,{width:400},(base)=>$emits('success',{src:img.src,base:base})):$emits('success',img);
  InputRef.value.value = "";
  upload_bool.value = true
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "me-upload",
    onClick: _cache[0] || (_cache[0] = $event => (InputRef.value.click()))
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "file",
      accept: "image/*",
      onChange: uploadChange,
      ref_key: "InputRef",
      ref: InputRef
    }, null, 544), [
      [_vShow, false]
    ]),
    _renderSlot(_ctx.$slots, "icon")
  ]))
}
}

}