import { ElMessage, ElMessageBox } from "element-plus";
import { changeWebIcon, changeWebHeadMeta, storeTheme } from "@/utils/changeWeb.js";
import { decrypt } from '@/utils/cro';
import {$apiUnionInfo} from "@/api/modules/store"
import store from "../store/index";

let limit = ["ShopCar", "Order", "Payment"]
let $ROUTER;
function RouterConfig(router) {
  $ROUTER = function (path) {
    
    if (path==500) {
      if (router.currentRoute.value.path.includes('/sales')) {
        router.push(`/sales/${path}`)
      }else{
        ElMessageBox.confirm(" 服务器错误请联系平台 (400-699-0208) ！", path, { confirmButtonText: '确定', cancelButtonText: '取消' })
      }
      return
    }
    if (!router.currentRoute.value.path.includes('/store')) {
      localStorage.clear()
      if (router.currentRoute.value.path.includes('/sales') || router.currentRoute.value.path.includes('/other/login')) {
        router.push('/other/login')
      } else {
        // store.commit("setUserInfo",{data:null})
        router.push('/')
      }
    }
  };

  router.beforeEach(async(to, from, next) => {
    if (localStorage.getItem('user_info') && localStorage.getItem('token') && to.path == "/other/login") {
      return next('/sales/home')
    }
    if (!localStorage.getItem('token') && to.path.includes('/sales')) {
      ElMessage.error({ message: '您当前尚未登录或登录已失效，请先登录' })
      store.commit("setUserInfo",{data:{}})
      next('/other/login')
      return
    }

    for (let item of limit) {
      if (!localStorage.getItem('token') && (item.includes(to.name) || to.path.includes('/user'))) {
        ElMessage.error({ message: '您当前尚未登录或登录已失效，请先登录' })
        next('/')
        return
      }
    }
    // 获取公司主题
    if(to.meta.info?.id){
      const {data} = await $apiUnionInfo({company_id:to.meta.info?.id})
      data[0].color = data[0].color ? data[0].color : '#e30011'
      localStorage.setItem("theme",JSON.stringify(data[0]))
    }else{
      localStorage.removeItem("theme")
    }
    salesAdmin(to, next)
    changeWebIcon(to.meta.info)  /* webIcon */
    // storeTheme(to) /* store主题 */
  });
  
  router.afterEach((to, from, next) => {
    if (to.meta.title) {
      document.title = (to.path.includes('sales')||to.path.includes('other')) ? '销售端 | '+to.meta.title : to.meta.title + ' | 网上交易商城 | 钢信宝'; /* meta改变title */
    }
    if (!to.path.includes('/sales') && !to.path.includes('/payment')) {
      sessionStorage.removeItem('permissions')
    }
    changeWebHeadMeta(to.meta.content)
    window.scrollTo(0, 0);
  });
}

// 路由权限
function salesAdmin(to, next) {
  if (to.meta.permissions) {
    const auth = decrypt(localStorage.getItem('auth')) === 'admin'
    // auth
    // return next()
    return auth ? next() :next('/sales/403')
  } else {
    return next()
  }
}


export { RouterConfig, $ROUTER }



