import WS_API from "@/config/websoket"
function useWebSocket(handleSuccess) {
    const ws = new WebSocket(WS_API)

    const init = () => {
        bindEvent()
    }

    function bindEvent() {
        ws.addEventListener("open", handleOpen, false);
        ws.addEventListener("close", handleClose, false)
        ws.addEventListener("error", handleError, false)
        ws.addEventListener("message", handleSuccess, false)
    }

    function handleOpen(e) {
        console.log("ws:open");
    }
    function handleClose(e) {
        console.log("ws:close");
    }
    function handleError(e) {
        // console.log("ws:error");
    }
    function handleSuccess(e) {
        // console.log("ws:success");
    }
    init();
    return ws;
}

export default useWebSocket