const CenterRoutes =[
  {
    path:"/user",
    component: () => import("@/view/user/index.vue"),
    redirect: "/user/home", 
    children:[
      {
        path: "/user/home",
        name: "CenterHome",//首页
        meta: { title: '个人中心首页' },
        component: () => import("@/view/user/home/index.vue"),
      },
      {
        path: "/user/lock/release",
        name: "LockRelease",
        meta: { title: '锁价发布' },
        component: () => import("@/view/user/lockPrice/Release.vue"),
      },
      {
        path: "/user/lock/balance",
        name: "LockDeposit",
        meta: { title: '我的账户' },
        component: () => import("@/view/user/lockPrice/Balance.vue"),
      },
      {
        path: "/user/balance",
        name: "MyBalance",
        meta: { title: '我的账户' },
        component: () => import("@/view/user/lockPrice/balance/index.vue"),
      },
      {
        path: "/user/lock/order",
        name: "LockOrder",
        meta: { title: '锁价订单' },
        component: () => import("@/view/user/lockPrice/Order.vue"),
      },
      {
        path: "/user/lock/steel/order",
        name: "steelLockOrder",
        meta: { title: '钢制品订单' },
        component: () => import("@/view/user/lockPrice/Order.vue"),
      },
      {
        path: "/user/lock/bill",
        name: "LockBill",
        meta: { title: '锁价流水' },
        component: () => import("@/view/user/lockPrice/Bill.vue"),
      },
      {
        path: "/user/lock/reward",
        name: "LockReward",
        meta: { title: '新客奖励' },
        component: () => import("@/view/user/lockPrice/Reward.vue"),
      },
      {
        path: "/user/customer",
        name: "UserCustomer",
        meta: { title: '客户管理' },
        component: () => import("@/view/user/customer"),
      },
      {
        path: "/user/bank",
        name: "Bank",//银行卡列表
        meta: { title: '银行卡列表' },
        component: () => import("@/view/user/bank/index.vue"),
      },
      {
        path: "/user/task",
        name: "UserTask",//任务管理
        meta: { title: '任务管理' },
        component: () => import("@/view/user/task/index.vue"),
      },
      //我的优惠券
      {
        path: "/user/coupon",
        name: "PlatformCoupon",//平台优惠券
        meta: { title: '平台优惠券' },
        component: () => import("@/view/user/coupon/PlatformCoupon.vue"),
      },
      {
        path: "/user/coupon/merchant",
        name: "MerchantCoupon",//商家优惠券
        meta: { title: '商家优惠券' },
        component: () => import("@/view/user/coupon/MerchantCoupon.vue"),
      },
      //我的福利
      {
        path: "/user/welfare/packet",
        name: "CenterPacket",//我的红包
        meta: { title: '我的红包' },
        component: () => import("@/view/user/welfare/Packet.vue"),
      },
      {
        path: "/user/welfare/reward",
        name: "reward",//交易抽奖
        meta: { title: '交易抽奖' },
        component: () => import("@/view/user/welfare/Dealreward.vue"),
      },
      {
        path: "/user/welfare/prize",
        name: "prize",//每日抽奖
        meta: { title: '每日抽奖' },
        component: () => import("@/view/user/welfare/LuckyDraw.vue"),
      },
      //账号管理
      {
        path: "/user/basis",
        name: "Basis",//身份认证
        meta: { title: '身份认证' },
        component: () => import("@/view/user/account/BasisNew.vue"),
      },
      {
        path: "/user/data",
        name: "Data",//资料修改
        meta: { title: '资料修改' },
        component: () => import("@/view/user/account/Data.vue"),
      },
      {
        path: "/user/approve",
        name: "Approve",//企业认证
        meta: { title: '企业认证' },
        component: () => import("@/view/user/account/Approve.vue"),
      },
      {
        path: "/user/open",
        name: "Open",//企业开户
        meta: { title: '企业开户' },
        component: () => import("@/view/user/account/Open.vue"),
      },
      {
        path: "/user/person/open",
        name: "PersonOpen",//提现认证
        meta: { title: '提现认证' },
        component: () => import("@/view/user/account/PersonOpen.vue"),
      },
      {
        path: "/user/enquiry/msg",
        name: "EnquiryMsg",//询价消息
        meta: { title: '询价消息' },
        component: () => import("@/view/user/enquiry/index.vue"),
      },
       //订单管理
      {
        path: "/user/order/buy",
        name: "buyOrder",//买入订单
        meta: { title: '买入订单' },
        component: () => import("@/view/user/order/buyOrder.vue"),
      },
      {
        path: "/user/order/sale",
        name: "saleOrder",//卖出订单
        meta: { title: '卖出订单' },
        component: () => import("@/view/user/order/saleOrder.vue"),
      },
      {
        path: "/user/order/enquiry",
        name: "EnquiryOrder",//询价列表
        meta: { title: '询价列表' },
        component: () => import("@/view/user/order/EnquiryOrder.vue"),
      },
      
      //合同管理
      {
        path: "/user/contract/sign",
        name: "SignContract",//签约合同
        meta: { title: '签约合同' },
        component: () => import("@/view/user/contract/SignContract.vue"),
      },
      {
        path: "/user/contract/settlement",
        name: "SettlementContract",//结算合同
        meta: { title: '结算合同' },
        component: () => import("@/view/user/contract/SettlementContract.vue"),
      },
      
      {
        path: "/user/deal/flow",
        name: "DealFlow",//交易流水
        meta: { title: '交易流水' },
        component: () => import("@/view/user/dealFlow/DealFlow.vue"),
      },

      {
        path: "/user/bill",
        name: "Bill",//电子回单
        meta: { title: '电子回单' },
        component: () => import("@/view/user/Bill.vue"),
      },

      //功能介绍
      {
        path: "/user/buy",
        name: "CenterBuy",//我要买货
        meta: { title: '我要买货' },
        component: () => import("@/view/user/introduce/Buy.vue"),
      },
      {
        path: "/user/sell",
        name: "CenterSell",//我要卖货
        meta: { title: '我要卖货' },
        component: () => import("@/view/user/introduce/Sell.vue"),
      },
      {
        path: "/user/payment",
        name: "CenterPayment",//钢付宝
        meta: { title: '钢付宝介绍' },
        component: () => import("@/view/user/introduce/Payment.vue"),
      },
      {
        path: "/user/sale",
        name: "CenterSale",//销售端
        meta: { title: '销售端介绍' },
        component: () => import("@/view/user/introduce/Sale.vue"),
      },
      //交易排行榜
      {
        path: "/user/ranking",
        name: "Ranking",//交易排行榜
        meta: { title: '交易排行榜' },
        component: () => import("@/view/user/ranking/index.vue"),
      },
    ]
  },
  {
    path: "/coupon",
    name: "Coupon",//优惠券
    meta: { title: '优惠券' },
    component: () => import("@/view/user/coupon/Coupon.vue"),
  },
  {
    path: "/user/contract/detail",
    name: "ContractDetail",//合同详情
    meta: { title: '合同详情' },
    component: () => import("@/view/user/contract/Detail.vue"),
  },

  
  // 密码控件路由
  {
    path: "/control/open/person",
    name: "ControlPersonOpen",//提现认证
    meta: { title: '提现认证' },
    component: () => import("@/view/user/account/PersonOpen.vue"),
  },
]
export default CenterRoutes