import { $ROUTER } from '@/config/router.config';
import axios from 'axios'
import { ElMessageBox } from 'element-plus';
export default function http(option, data) {
    return new Promise((resolve, reject) => {
        // 1.创建axios的实例
        const instance = axios.create({
            headers: {
                // ...option.headers
            },
            
            baseURL: /* process.env.VUE_APP_API || */ 'https://gangxinbao.cn/api',
            timeout: 60000
        });
        
        // 请求拦截
        instance.interceptors.request.use(config => {
            // // 默认携带token
            // let token = window.localStorage.getItem('token')
            // if (config.token) {
            //   config.headers.token = token
            // }
            return config
        }, err => {
            return err
        })
        // 响应拦截
        instance.interceptors.response.use(response => {
            return response.data
        }, err => {
            console.log(err.response);
            if(err.response?.status==500){
                $ROUTER(500)
                err.response.msg = err.response.data?.message
                err.response.status = 0
                return err.response
            }
            return err
        })
        // 2.传入对象进行网络请求
        instance(option, data).then(res => {
            if (res?.status == -1) {
                $ROUTER('/')
            } else {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}


