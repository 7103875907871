import http from "./http"
const newUrl = 'https://pay.gxb.cn/api'

// 个人信息(登录) {token}
export const $apiUserInfo = (data) => http({
  method: "post",
  url: "/user/update/info",
  params: data,
})

/** 个人卡片信息=> @param=> {id} */
export function $apiUserCard(data) {
  return http({
    method: "get",
    url: `/userCard?id=${data}`,
  })
}

// 公司信息 data{id}
export const $apiCompanyInfo = (data) => http({
  method: "post",
  url: "/company/info",
  params: data,
});

// 公司认证状态 token
export const $apiCompanyState = (data) => http({
  method: "post",
  url: "company/approve/state",
  params: data,
});

// # data{companyId}   是否是联盟商家（原接口）
export const $apiUserPermissions = (data) => http({
  method: "get",
  url: "/if/union/company",
  params: data,
});

// 是否是联盟商家 区分	2 为定金锁价 3为预定促销 4位钢铁制品
export const $apiUnionMerchant = (data) => http({
  method: "post",
  url: newUrl+"/if/union/merchant",
  data: data,
});

// # admin 是否是管理员
export const $apiAdminPermissions = (data) => http({
  method: "post",
  url: "/company/permissions",
  params: data,
});

// 个人信息修改
export const $editUserInfo = (data) => http({
  method: "post",
  url: "/user/update",
  params: data,
})

export const $subUserOrder = (data) => http({
  method: "post",
  url: "/user/goods/order",
  params: data,
})
//用户余额
export const $getUserBalanceInfo = (data) => http({
  method: "post",
  url: "/get/user/balance",
  params: data,
})

//用户询价消息条数
export const $apiEnquiryNum = (data) => http({
  method: "get",
  url: "/user/prompt/news",
  params: data,
})
//用户询价消息
export const $apiMessageEnquiry = (data) => http({
  method: "get",
  url: "/Inquiry/message/list",
  params: data,
})
//用户询价消息详情
export const $apiEnquiryDetail = (data) => http({
  method: "POST",
  url: "/Inquiry/message/info",
  params: data,
})
//角色选择 1.买家2.卖家
export const $apiSelectIdentity = (data) => http({
  method: "POST",
  url: newUrl+"/user/select/save",
  data: data,
})


