// 修改ico
const img = 'https://cdn.gangxinbao.cn/'
export function changeWebIcon(store) {
  let head = document.querySelector('head');
  let link = document.createElement('link');
  if (head.querySelector('web-link-icon')) {head.removeChild('.web-link-icon')}
  link.rel = "icon";
  link.className = "web-link-icon";
  link.href = store ? (store.icon ? img + store.icon:require("../assets/gxb_ico.png")) : require("../assets/gxb_ico.png")
  head.appendChild(link);
}
export function changeWebHeadMeta(cont) {
  let head = document.querySelector('head');
  let meta = document.createElement('meta');
  document.querySelector('meta[name="keywords"]').setAttribute('content', '钢,钢材,钢信,钢信通,钢信宝,钢材今天价格,不锈钢, 钢铁在线交易,钢铁线上交易,钢材期货,钢材期货交易,钢材走势,钢铁价格走势,螺纹钢现货价格,不锈钢现货价格')
  document.querySelector('meta[name="description"]').setAttribute('content', '钢信宝，致力于打造以信息技术为核心的第三方钢贸交易服务平台，目前旗下拥有包括APP、网站、期刊、名录图在内的36个独立产品，产品及业务覆盖13省、56座城市近5万家企业。热线：400-699-0208')
  meta.content = cont;
  head.appendChild(meta)
}
