import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/close.png'


const _withScopeId = n => (_pushScopeId("data-v-2f8bfb2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bg"
}

import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";
import {ref, watch,} from "vue";
import Login from './index.vue'
import { useVModel } from "@vueuse/core";

export default {
  props: {
  showLogin: Boolean,
  close: String
},
  emits: ["update:showLogin","close"],
  setup(__props, { emit: emits }) {

const props = __props

let route = useRoute();
const isDialog = useVModel(props, 'showLogin',  emits)
let showLoginPop = ref(false)

// watch(() => props.showLogin, (val) => {
//   console.log(111);
//   showLoginPop.value = true
// })

function onLoginClose() {
  emits("update:showLogin",false)
  emits("close",false)
}

return (_ctx, _cache) => {
  return (_unref(isDialog) || _unref(route).query.code)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Login),
        _createElementVNode("img", {
          lazy: "",
          src: _imports_0,
          alt: "",
          class: "close",
          onClick: _cache[0] || (_cache[0] = $event => (onLoginClose()))
        })
      ]))
    : _createCommentVNode("", true)
}
}

}