// 以逗号分割金额
export const handleMoney = (money) => {
  money = money.toString()
  if (isNaN(Number(money))) return "--";
  let _money = "";
  let _decimal = "";

  if (money.split(".").length > 1) {
    _decimal =
      "." +
      (money.split(".")[1] ? money.split(".")[1] : Number(money.split(".")[1]));
    _money = money.split(".")[0];
  } else {
    _money = money;
  }

  if (_money.length < 4) {
    return _money + _decimal;
  }

  let newmoney = "";

  for (let i = 0; i < _money.length; i++) {
    if ((i + (3 - (_money.length % 3))) % 3 == 0 && i > 0) {
      newmoney += "," + _money[i];
    } else {
      newmoney += _money[i];
    }
  }
  return newmoney + _decimal;
};

// 数字添加单位
export function addNumberUnit(num) {
  // decimal小数点几位
  function strNumSize(tempNum) {
    if(!tempNum) return 0
    let stringNum = tempNum.toString()
    let index = stringNum.indexOf(".")
    let newNum = stringNum;
    if (index != -1) {
      newNum = stringNum.substring(0, index)
    }
    return newNum.length
  }
  let moneyUnits = ['',"万", "亿", "万亿"]
  let dividend = 10000;
  let currentNum = num;
  //转换数字 
  let currentUnit = moneyUnits[0];
  //转换单位 
  for (let i = 0; i < 4; i++) {
    currentUnit = moneyUnits[i]
    if (strNumSize(currentNum) < 5) {
      break;
    }
    currentNum = currentNum / dividend
  }
  const newMoney = Number(Number(currentNum).toFixed(2))
  const info = newMoney + currentUnit
  return {newMoney,currentUnit,info};
}


