import http from "./http"
const yxUrl = 'https://api.yx.gxb.cn/api'
export const $apiWxLoginAppid = (data) =>  http({ method: 'post', url:'/wechat/params',params: data }) /* 获取参数 */
export const $apiWxLoginAttest = (data) =>http({ method: 'post', url:'/wechat/login',params: data }) /* 登录验证是否绑定手机号 */
export const $apiWxLoginBindPhone = (data) =>  http({ method: 'post', url:'/bind/phone',params: data }) /* 绑定手机 */

/* 扫码登录 获取二维码；链接*/
export const $apiGetLoginCode = (data) =>  http({ method: 'get', url:yxUrl+'/code/create',data: data })
// 扫码登录轮询接口
export const $apiGetCodeLoginInform = (data) =>  http({ method: 'post', url:yxUrl+'/login/to/inform',data: data })
