import {$apiGetCodeLoginInform} from "@/api/modules/login.js";
// 扫码登录时轮询接口
export const useRequestLoginCode = async (code, cb) => {
  const res = await $apiGetCodeLoginInform({ code })
  console.log(res);
  if (res && res.status == 1) {
      return res.data
  } else {
      if (res.msg.type == 1) {
        cb(2)
      }
  }
}