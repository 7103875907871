import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/sales/wx_login.png'


const _withScopeId = n => (_pushScopeId("data-v-7cfb56dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ws_login" }

import { $apiWxLoginAppid, $apiWxLoginAttest } from '@/api/modules/login';
import { ref, reactive, getCurrentInstance} from 'vue'
import { Toast } from "vant";
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from "element-plus";

export default {
  props: {
  whereLoin:String,
  isAgreen:Boolean,
},
  emits: 'onLogin',
  setup(__props, { emit: $emits }) {

const props = __props

const route = useRoute(),router = useRouter()
const {proxy:{$msg}} = getCurrentInstance() 

function urlencode(str) {  
  str = (str + '').toString();   
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
  replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
}
// 获取appid

async function getWxLoginAppid() {
  if(props.isAgreen){
    const { status, data, msg} = await $apiWxLoginAppid() 
    if (status) {
      // 自己生成二维码登录
      // <div id="login_container"></div>
      // new WxLogin({
      //   self_redirect:false,
      //   id:"login_container", 
      //   appid:data.appId,
      //   scope:data.scope,
      //   redirect_uri: urlencode("https://gxb.cn/#/other/login"),
      //   state:data.state,
      // });
      console.log(route);
      window.location.href =`https://open.weixin.qq.com/connect/qrconnect?appid=${data.appId}&redirect_uri=${encodeURIComponent("https://gangxinbao.cn/api/wechat/code")}&scope=${data.scope}&state=${encodeURIComponent(props.whereLoin=='deal'?`https://jy.gxb.cn${route.path}`:"https://jy.gxb.cn")}`
    }else{
      $msg.warn(msg)
    }
  }else{
    ElMessage.warning('请勾选同意政策后再登录')
  }
}



// 登陆验证
wxLoginAttest()
async function wxLoginAttest() {
  if (!route.query.code)return;
  // if(!router.options.history.base.includes("code="))return;
  // if(!router.options.history.base.split("code=")[1])return;
  Toast.loading({ message: '正在登录...', overlay: true, forbidClick:false,duration:0});
  const params = {code:route.query.code,state:route.query.state}
  const { status, data, msg} = await $apiWxLoginAttest(params) 
  if (status) {
    if (data.param) {
      $msg.warn("您需要绑定手机号才能使用微信登录！"),
      Toast.clear(),
      router.push({path:"/bind/phone",query:{unionId:data.param,path:props.whereLoin=='deal'?route.path:''}})
    }else{
      $emits("onLogin",data.token)
      Toast.clear()
    }
  }else{
    $msg.warn(msg)
  }
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "",
      class: "imgs",
      onClick: getWxLoginAppid,
      title: "微信登录"
    })
  ]))
}
}

}