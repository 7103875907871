const OtherRoutes = [
  //登录
  {
    path: "/other/login",
    meta: { title: "登录", },
    component: () => import("@/view/sales/sales-login/index.vue"),
  },
  //绑定手机
  {
    path: "/other/login/phone",
    meta: { title: "登录", },
    component: () => import("@/view/sales/sales-login/bindPhone.vue"),
  },/* 资金流水 */
  {
    path: "/preview",
    name: "Preview",
    meta: { title: "合同预览" },
    component: () => import("@/view/preview/index.vue"),
  },

]
const DetailsRoutes = [
  /* 合同编辑 */
  {
    path: "/sales/contract/edit", name: "ContractEdit", title: "合同编辑",
    meta: { title: "合同编辑", hidden: false, icon: `&#xe76f;` },
    component: () => import("@/view/sales/sales-contract/edit.vue"),
  },
  /* 订单详情 */
  {
    path: "/sales/order/details",
    meta: { title: "订单详情", },
    component: () => import("@/view/sales/sales-order/details.vue"),
  },
  /* 合同详情 */
  {
    path: "/sales/contract/details",
    meta: { title: "合同详情", },/* details  de1*/
    component: () => import("@/view/sales/sales-contract/details.vue"),
  },
  /* 交易详情 */
  {
    path: "/sales/record/details",
    meta: { title: "交易详情", },/* details  de1*/
    component: () => import("@/view/sales/sales-record/details.vue"),
  },

]
export const AsideRoutes = [
  /* 首页 */
  {
    path: "/sales/home",
    name: "SalesHome",
    title: "首页",
    meta: { title: "首页", hidden: false, icon: `&#xe7a7;` },
    component: () => import("@/view/sales/sales-home/index.vue"),
  },
  /* 我的合同 */
  {
    path: "/sales/contract",
    name: "SalesContract",
    redirect: "/sales/contract/convention",
    title: "我的合同",
    prompt: "",
    meta: { title: "我的合同", hidden: false, icon: `&#xe75a;` },
    component: () => import("@/view/sales/sales-contract/index.vue"),
    children: [
      /* 签约合同 */
      {
        path: "/sales/contract/convention",
        title: "签约合同",
        name: "ContractC",
        meta: { title: "签约合同", },
        component: () => import("@/view/sales/sales-contract/convention.vue"),
      },
      /* 结算合同 */
      {
        path: "/sales/contract/settlement",
        title: "结算合同",
        name: "ContractS",
        meta: { title: "结算合同", },
        component: () => import("@/view/sales/sales-contract/settlement.vue"),
      },
      /* 合同模板 */
      {
        path: "/sales/contract/template",
        title: "合同模板",
        name: "ContractT",
        meta: { title: "合同模板", },
        component: () => import("@/view/sales/sales-contract/template.vue"),
      },
    ],
  },
  /* 我的订单 */
  {
    /* 订单管理 */
    path: "/sales/order",
    redirect: "/sales/order/default",
    name: "SalesOrder",
    title: "我的订单",
    prompt: "",
    component: () => import("@/view/sales/sales-order/view.vue"),
    meta: { title: "我的订单", hidden: false, icon: `&#xe751;` },
    children: [
      { /* 锁价订单 */
        path: "/sales/order/deal",
        title: "锁价订单",
        name: "OrderDeal",
        meta: { title: "锁价订单", },
        component: () => import("@/view/sales/sales-order/deal.vue"),
      },
      { /* 钢制品订单 */
        path: "/sales/order/gph",
        title: "钢制品订单",
        name: "OrderGPH",
        meta: { title: "钢制品订单", },
        component: () => import("@/view/sales/sales-order/gph.vue"),
      },
      { /* 锁价订单 */
        path: "/sales/order/record",
        title: "交易记录",
        name: "OrderTransactionRecord",
        meta: { title: "交易记录", },
        component: () => import("@/view/sales/sales-order/TransactionRecord.vue"),
      },
      { /* 发布订单 */
        path: "/sales/order/publish",
        title: "发布订单",
        name: "OrderPublish",
        meta: { title: "发布订单", },
        component: () => import("@/view/sales/sales-order/publish.vue"),
      },
      { /* 系统订单 */
        path: "/sales/order/default",
        title: "系统订单",
        name: "OrderDef",
        meta: { title: "系统订单", },
        component: () => import("@/view/sales/sales-order/index.vue"),
      },
      { /* 主动订单 */
        path: "/sales/order/custom",
        title: "主动订单",
        name: "OrderCustom",
        meta: { title: "主动订单", },
        component: () => import("@/view/sales/sales-order/custom.vue"),
      },
    ],
  },
  /* 我的消息 */
  {
    path: "/sales/messages",
    name: "SalesMessage",
    title: "我的消息",
    prompt: "",
    meta: { title: "我的消息", hidden: false, icon: `&#xe7b2;` },
    component: () => import("@/view/sales/sales-messages/index.vue"),
  },
  /* 我的询价 */
  {
    path: "/sales/order/askprice",
    title: "我的询价",
    name: "AskPrice",
    meta: { title: "我的询价", icon: `&#xe770;` },
    component: () => import("@/view/sales/sales-order/askprice.vue"),
  },
  /* 我的客户 */
  {
    path: "/sales/customer",
    name: "SalesCustomer",
    title: "我的客户",
    meta: { title: "我的客户", hidden: false, icon: `&#xe74b;` },
    component: () => import("@/view/sales/sales-customer/index.vue"),
  },
  /* 我的流水 */
  {
    path: "/sales/UserRecord",
    name: "SalesUserRecord",
    title: "我的流水",
    meta: { title: "我的流水", hidden: false, icon: `&#xe768;` },
    component: () => import("@/view/sales/sales-record/user.vue"),
  },
  /* 应收款 */
  {
    path: "/sales/collection",
    name: "SalesCollection",
    title: "应收款",
    meta: { title: "应收款", hidden: false, icon: `&#xe7a0;` },
    component: () => import("@/view/sales/sales-collection/index.vue"),
  },
  /* 电子回单 */
  {
    path: "/sales/invoice",
    name: "SalesInvoice",
    title: "电子回单",
    meta: { title: "电子回单", hidden: false, icon: `&#xe768;` },
    component: () => import("@/view/sales/sales-invoice/index.vue"),
  },
  /* 库存管理* */
  {
    path: "/sales/inventory",
    name: "SalesInventory",
    title: "库存管理*",
    meta: { permissions: true, title: "库存管理", hidden: false, icon: `&#xe6f0;` },
    component: () => import("@/view/sales/sales-inventory/index.vue"),
  },
  /* 业务员管理* */
  {
    path: "/sales/seats",
    name: "SalesSeats",
    title: "业务员管理*",
    meta: { permissions: true, title: "业务员管理", hidden: false, icon: `&#xe6ef;` },
    component: () => import("@/view/sales/sales-seats/index.vue"),
  },
  /* 报价管理* */
  {
    path: "/sales/selling",
    name: "SalesSelling",
    title: "报价管理*",
    meta: { permissions: true, title: "报价管理", hidden: false, icon: `&#xe76f;` },
    component: () => import("@/view/sales/sales-selling/index.vue"),
  },
  /* 交易流水* */
  {
    path: "/sales/record",
    name: "SalesRecord",
    title: "交易流水*",
    meta: { permissions: true, title: "交易流水", hidden: false, icon: `&#xe776;` },
    component: () => import("@/view/sales/sales-record/index.vue"),
  },
  /* 客户统计* */
  {
    path: "/sales/customer/statistics",
    name: "SalesCustomerStatistics",
    title: "客户统计*",
    meta: { permissions: true, title: "客户统计", hidden: false, icon: `&#xe747;` },
    component: () => import("@/view/sales/sales-customer/statistics.vue"),
  },
  // { /* 优惠券 */
  //     path: "/sales/coupon", name: "SalesCoupon", title: "优惠券",
  //    redirect: "/sales/coupon/public",
  //     meta: { title: "优惠券", hidden: false, icon: `&#xe770;` },
  //     component: () => import("@/view/sales/sales-coupon/index.vue"),
  //     children: [
  //         { /* 平台优惠券 */
  //             path: "/sales/coupon/public",
  //             title: "平台优惠券",
  //             name: "CouponP",
  //             meta: { title: "平台优惠券", },
  //             component: () => import("../../view/sales/sales-coupon/coupon-public.vue"),
  //         },
  //         { /* 商家优惠券 */
  //             path: "/sales/coupon/business",
  //             title: "商家优惠券",
  //             name: "CouponB",
  //             meta: { title: "商家优惠券", },
  //             component: () => import("../../view/sales/sales-coupon/coupon-business.vue"),
  //         },
  //     ]
  // },

  // { /* VIP管理 */
  //     path: "/sales/vip",
  //     name: "SalesVIP",
  //     title: "VIP申请",
  //     meta: { title: "vip申请", hidden: false, icon: `&#xe775;` },
  //     component: () => import("@/view/sales/sales-vip/index.vue"),
  // },

]
const Error = [
  /* 交易详情 */
  {
    path: "/sales/403",
    meta: { title: "403", },/* details  de1*/
    component: () => import("@/view/sales/sales-error/403.vue"),
  },
  {
    path: "/sales/404",
    meta: { title: "404", },/* details  de1*/
    component: () => import("@/view/sales/sales-error/404.vue"),
  },
  {
    path: "/sales/500",
    meta: { title: "500", },/* details  de1*/
    component: () => import("@/view/sales/sales-error/500.vue"),
  },
]
const SalesRoutes = [
  ...OtherRoutes,
  // => 侧边路由
  {
    path: "/sales",
    title: "销售端",
    component: () => import(/* webpackChunkName: "sales" */ "@/view/sales/index.vue"),
    children: [...AsideRoutes, ...DetailsRoutes, ...Error]
  },
];
export default SalesRoutes