// 图片转64 file
function fileToBase64(file) {
  const promise = new Promise((resolve, reject) => {
    let reader = new FileReader();
    let imgUrl = URL.createObjectURL(file);
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = { base: reader.result, src: imgUrl, }
      resolve(img)
    };
    reader.onerror = function (error) {
      reject("上传失败")
    };
  })
  return promise
}





//将图片进行等比压缩
function compressImage(path, obj={width:500}, callback) {
  console.log(path);
  let img = new Image();
  img.src = path;
  img.onload = function () {

    let that = this;
    // 默认按比例压缩
    let w = that.width, h = that.height, scale = w / h;
    // if (that.width > obj.width) {
      w = obj.width  || w;
      h = obj.height || w / scale;
      let quality = 1;  // 默认图片质量为0.7
      //生成canvas
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      // 创建属性节点
      let anw = document.createAttribute("width");
      anw.nodeValue = w;
      let anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);
      // 图像质量
      if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
        quality = obj.quality;
      }
      // quality值越小，所绘制出的图像越模糊
      let base64 = canvas.toDataURL('image/jpeg', quality);
      // 回调函数返回base64的值
      callback(base64);
    // } else {
    //   callback({status:0,msg:"你规定的图片宽度大于原图宽度",src:path});
    // }
  }
}
// convertImageToBase64('https://cdn.gangxinbao.cn/avatar/HUFRoH3pDtEGO785xOJE7Gw9RzJcslWtZXJJ2yAo.jpg')
function convertImageToBase64(image) {
  let canvas = document.getElementById("canvas");
  let ctx = canvas.getContext('2d');
  let imgObj = new Image();
  // 先设置图片跨域属性
  imgObj.setAttribute("crossOrigin", 'anonymous')
  // 再给image赋值src属性，先后顺序不能颠倒
  imgObj.src = image;
  // 当图片加载完成后，绘制图片到canvas
  imgObj.onload = function () {
    // 设置canvas宽高等于图片实际宽高
    canvas.width = this.width;
    canvas.height = this.height;
    ctx.drawImage(this, 0, 0);
    // 将图片转成base64格式
    let base = canvas.toDataURL("image/jpeg", 0.5); // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
    console.log(base);
  }
  
}
const useImageHandler = { fileToBase64, compressImage, }
export default useImageHandler