import http from "./http"
const baseUrl = 'https://pay.gxb.cn/api'

// 哀悼接口 
export const $apiMourning = (data) =>http({ method: 'get', url:'/unified/mourning',data }) 

// 获取ip 
export const $apiGetIp = (data) =>http({ method: 'post', url:baseUrl+'/get/client/ip',data }) 

// 已开通的地区 
export const $apiOpenAddress = (data) =>http({ method: 'post', url:baseUrl+'/open/area/list',data })

// 钢厂列表
export const $apiProductList = (data) =>http({ method: 'post', url:'/product/list',data })
// 钢厂详情信息
export const $apiProductInfo = (data) =>http({ method: 'post', url:'/product/info',data }) 


// 联盟商家信息
export const $apiUnionInfo = (data) =>http({ method: 'post', url:baseUrl+'/home/merchants',data }) 

// 首页
// 钢厂信息
export const $apiHomeProductInfo = (data) =>http({ method: 'get', url:'/steel/mill',data }) 
//询价品名列表
export const $apiInquiryNameList = (data) =>http({ method: 'get', url:'/total/types/list',data }) 
//发布询价
export const $apiReleaseInquiry = (data) =>http({ method: 'post', url:'/Goods/price/inquiry',data }) 

//首页现货采购的筛选
export const $apiHomeShopScreen = (data) =>http({ method: 'get', url:'/home/type',data }) 
//首页现货采购 typeId：大分类，name:小分类
export const $apiHomeShopList = (data) =>http({ method: 'post', url:'/home/goods',data }) 
//现货采购的搜索 以分类搜索
export const $apiShopTypeSearch = (data) =>http({ method: 'post', url:'/seckill/goods/list',data }) 
//现货采购的搜索 以关键字搜索
export const $apiShopSearch = (data) =>http({ method: 'post', url:'/goods/search',data }) 

