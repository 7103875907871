import { createStore } from "vuex";
import UserStore from "./modules/UserStore";
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const store = createStore({
  //长期缓存
  // plugins:[createPersistedState()], 
  state,
  getters,
  mutations,
  actions,
  modules: { $user: UserStore }
});
export default store;
