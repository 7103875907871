import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { reactive,ref, watch,defineEmits,defineProps } from 'vue-demi'
import { ElMessage,ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";

export default {
  props: {
  codeUrl:String,
  mobile:String,
  placeholder:String,
  val:String,
},
  emits: ['getCode','getRandom'],
  setup(__props, { emit: emits }) {

const props = __props

let token = localStorage.getItem("token");
let router = useRouter()
let ruleForm = reactive({
  verifyCode:'',
})


// 传验证码
watch(()=>ruleForm.verifyCode,(val)=>{
  ruleForm.verifyCode = ruleForm.verifyCode.trim()
  if(isNaN(Number(val)) || val.includes('.')){
    ruleForm.verifyCode = ruleForm.verifyCode.slice(0,val.length-1)
  }
  emits('getCode',val)
})
watch(()=>props.val,()=>{ruleForm.verifyCode = props.val })
let codeFlag = ref(true) //设置开关60秒内只能发送一次
let countdown = null //设置定时器来控制倒计时
let time = 60 //倒计时
let codeText = ref("获取验证码")
// 获取验证码
const getCode = ()=>{
    // 还未发送短信
    if(codeFlag.value){
        // 发送验证码的请求
        axios({
          method: "post",
          url: `${props.codeUrl}`,
          data: { token,mobile:props.mobile,phone:props.mobile},
        }).then((data) => {
          let msg = data.data.data
          // 未绑定企业去绑定
          if(data.data.msg == '尚未绑定企业'){
            ElMessageBox.confirm('您当前未绑定企业,请前往认证','提示',{cancelButtonText: '取消',confirmButtonText: '去认证',}).then(() => {router.push('/user/approve')})
          }else{
            // 消息提示
            if(props.codeUrl == 'pay/phone/verifycode/apply')msg = data.data.status==1?'发送成功':data.data.msg.ret_msg
            else msg = data.data.status==1?'验证码发送成功,如果未收到验证码，请在60秒后重新发送': (data.data.msg || data.data.info)
            let type = data.data.status==1?'success':'error'
            ElMessage({
                message: `${msg}`,
                type: `${type}`,
            });
          }
          // 成功发送验证码时
          if(data.data.status == 1){
            codeFlag.value = false
            countdown = setInterval(() => {
                time--
                if(time<10){
                    time = "0"+time
                }
                codeText.value = `${time}s重新发送`
                if(time == 0){
                    clearInterval(countdown)
                    codeFlag.value = true
                    codeText.value = `获取验证码`
                    time = 61
                }
            }, 1000);
          }
        });
    }else{
      ElMessage.success({message: "短信验证码已发送"});
    }
}

return (_ctx, _cache) => {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: "验证码",
    class: "code"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        modelValue: _unref(ruleForm).verifyCode,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_unref(ruleForm).verifyCode) = $event)),
        placeholder: __props.placeholder || '请输入短信验证码',
        onInput: _ctx.codeChange
      }, {
        append: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = $event => (getCode())),
            class: _normalizeClass(["code_btn flex_r", _unref(codeFlag)?'no_send_code':'send_code' ])
          }, _toDisplayString(_unref(codeText)), 3)
        ]),
        _: 1
      }, 8, ["modelValue", "placeholder", "onInput"])
    ]),
    _: 1
  }))
}
}

}