import HelpRouters from "./HelpRoutes"
const DealRouters = [
    {
        path: "/deal",
        name: "Deal", //电子交易中心
        component: () => import("@/view/deal/index.vue"),
        children: [
            {
                path: "/",
                name: "Home", //电子交易中心
                meta: {
                    title: "电子交易中心",
                },
                component: () => import("@/view/deal/newHome/index.vue"),
            },
            {
                path: "/deal/deposit",
                name: "DealDeposit", //定金锁价
                meta: { title: "定金锁价" },
                component: () => import("@/view/deal/deposit/index.vue"),
            },
            {
                path: "/order/detail",
                name: "OrderDetail", //订单详情
                meta: { title: "订单详情" },
                component: () => import("@/view/deal/deposit/OrderDetail.vue"),
            },
            {
                path: "/deal/pre/order",
                name: "DealPreOrder", //新客促销
                meta: { title: "新客促销" },
                component: () => import("@/view/deal/preOrder/index.vue"),
            },
            {
                path: "/deal/steel/product",
                name: "steelProduct", //钢铁制品专区
                meta: { title: "钢铁制品专区" },
                component: () => import("@/view/deal/steelProduct/index.vue"),
            },
            {
                path: "/gph/order/detail",
                name: "GphOrderDetail", //钢品汇订单详情
                meta: { title: "订单详情" },
                component: () => import("@/view/deal/steelProduct/OrderDetail.vue"),
            },
            {
                path: "/deal/inquiry",
                name: "DealInquiry", //现货资源
                meta: { title: "现货资源" },
                component: () => import("@/view/deal/inquiry/Inquiry.vue"),
            },
            {
                path: "/deal/union",
                name: "Union", //交易联盟专区(新)
                meta: { title: "联盟商家" },
                component: () => import("@/view/deal/union/Union.vue"),
            },
            // {
            //     path: "/deal/vip",
            //     name: "Vip", //vip专区
            //     meta: { title: "vip专区" },
            //     component: () => import("@/view/vip/index.vue"),
            // },
            {
                path: "/deal/brand",
                name: "Brand", //品牌产品专区
                meta: { title: "品牌产品专区" },
                component: () => import("@/view/deal/brand/index.vue"),
            },
            {
                path: "/deal/new/brand",
                name: "NewBrand", //新品牌产品专区
                meta: { title: "品牌产品专区" },
                component: () => import("@/view/deal/brand/Brand.vue"),
            },
            {
                path: "/deal/brand/detail",
                name: "BrandDetail", //品牌产品专区详情
                meta: { title: "品牌产品专区详情" },
                component: () => import("@/view/deal/brand/Detail.vue"),
            },
            {
                path: "/deal/goods",
                name: "Goods", //网上大超市
                meta: { title: "网上大超市" },
                component: () => import("@/view/deal/goods/Goods.vue"),
            },
            {
                path: "/goods/detail",
                name: "GoodsDetail", //商品详情
                meta: { title: "商品详情" },
                component: () => import("@/view/deal/goods/GoodsDetail.vue"),
            },
            {
                path: "/deal/enquiry",
                name: "Enquiry", //在线询价
                meta: { title: "在线询价" },
                component: () => import("@/view/deal/enquiry/index.vue"),
            },
            {
                path: "/deal/enquiry/fast",
                name: "FastEnquiry", //议价交易
                meta: { title: "议价交易" },
                component: () => import("@/view/deal/enquiry/FastEnquiry/index.vue"),
            },
            {
                path: "/deal/enquiry/fast/detail",
                name: "FastEnquiryDetail", //议价详情
                meta: { title: "议价详情" },
                component: () => import("@/view/deal/enquiry/FastEnquiry/Detail.vue"),
            },
            ...HelpRouters,
        ],
    },
];


export default DealRouters