import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48fdc35a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

import ServiceChat from "./components/me/ServiceChat.vue";
import Sidebar from "@/components/Sidebar.vue";
import WebSocket from "./view/chat/WebSocket.vue";
import { provide, ref, nextTick, reactive, toRefs, } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoginPopup from "./view/login/LoginPopup";
import axios from '@/api/axios.js'
import { $apiUserInfo } from '@/api/modules/user'
import { $apiMourning, $apiGetIp } from '@/api/modules/deal'
import { useStore } from "vuex";
import { isObject } from "vant/lib/utils";
import SelectIdentity from "@/components/SelectIdentity.vue";

export default {
  setup(__props) {

const store = useStore()
const { token } = toRefs(store.state)
const userInfo = JSON.parse(localStorage.getItem('user_info'))
const router = useRouter();
const route = useRoute();
const isSales = process.env.VUE_APP_PATH == '/sales/home'
const chat_show = ref(false)
chat_show.value = !eval(process.env.VUE_APP_SALES)
// 获取ip
async function getIp() {
  const ip = await $apiGetIp()
  localStorage.setItem('ip', ip)
}
!localStorage.getItem('ip') && getIp()
const originURL = ['bxgb2b', 'lwgb2b']

// 跨站传token
window.addEventListener('message', function (e) {
  const index = originURL.find(item => e.origin.includes(item))
  if (index && e.data) {
    localStorage.setItem('token', e.data);
    getUserInfo(e.data)
  }
}, false);
// 跨站传token同时存用户信息
const getUserInfo = async (token) => {
  if(token){
    const { data,status } = await $apiUserInfo({ token })
    status&&localStorage.setItem('user_info', JSON.stringify(data))
  }
}
// 刷新获取用户信息
if (token.value) {
  if(!isObject(userInfo)){
    localStorage.removeItem('user_info')
  }
  store.dispatch('getUserInfo', { token: token.value, reload: (() => { }) })
}

// 缓存基础信息
(!localStorage.getItem('publicInfo')) && axios({ method: "get", url: "setting" }).then((data) => {
  localStorage.setItem('publicInfo', JSON.stringify(data.data.data))
});


let view_show = ref(true);
let login_show = ref(false);
let socket_show = ref(false);
let socket_chatId = ref("");
provide("login_show", loginShow);
const login_close = ref("")
function loginShow(e) {
  login_close.value = e || ""
  login_show.value = true
}

provide("openSocket", openSocket);
// 打开聊天窗
function openSocket(pid, state) {
  socket_chatId.value = pid || "";
  if (socket_show.value) {
    socket_show.value = false;
    setTimeout(() => {
      socket_show.value = true;
    }, 500);
  } else {
    socket_show.value = true;
  }
}
provide("reload", reloadViews);
async function reloadViews() {
  view_show.value = false;
  await nextTick();
  view_show.value = true;
}

//哀悼灰色滤镜
const mourning = async () => {
  const { status } = await $apiMourning()
  if (status) { document.documentElement.style.filter = 'grayscale(1)' }
}
mourning()

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(view_show))
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    (!_unref(token))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(LoginPopup), {
            showLogin: _unref(login_show),
            "onUpdate:showLogin": _cache[0] || (_cache[0] = $event => (_isRef(login_show) ? (login_show).value = $event : login_show = $event)),
            close: login_close.value
          }, null, 8, ["showLogin", "close"])
        ]))
      : _createCommentVNode("", true),
    (_unref(socket_show))
      ? (_openBlock(), _createBlock(WebSocket, {
          key: 2,
          chatId: _unref(socket_chatId),
          onClose: _cache[1] || (_cache[1] = $event => (_isRef(socket_show) ? socket_show.value = false : socket_show = false))
        }, null, 8, ["chatId"]))
      : _createCommentVNode("", true),
    (!isSales && !_unref(route).meta.socket)
      ? (_openBlock(), _createBlock(Sidebar, { key: 3 }))
      : _createCommentVNode("", true),
    (_unref(token))
      ? (_openBlock(), _createBlock(SelectIdentity, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

}