/**
 * 
 * @param {time} time  倒计时多少s
 *    
 */
 export function useCountDown(time = 30, endFn, startFn) {
  let timer = null // 定时器实例
  function start() {
      startFn && startFn()
      timer = setInterval(() => {
          if (time == 0) {
              endFn && endFn()
              clearInterval(timer) //清空定时器
              return
          } else {
              time -= 1

          }
      }, 1000);
  }

  function stop() {
      clearInterval(timer)
  }
  start()
  return stop
} 