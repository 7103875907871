import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0238c562"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"overflow-y":"auto","min-height":"100px"} }
const _hoisted_4 = {
  key: 0,
  class: "box flex1"
}
const _hoisted_5 = ["onClick"]

import SendGoodsItem from './SendGoodsItem.vue'
import { ref, watch, computed } from 'vue'
import axios from 'axios'
import { useVModel  } from "@vueuse/core"

export default {
  props: ['isShow'],
  emits: ['close'],
  setup(__props, { emit: emits }) {

const props = __props



const isDialog = useVModel(props, 'isShow',  emits)
const tabsList = ref([])

//  初始化数据
const initDataList = async () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'))
  const res = await axios.post('https://chat.gangxinbao.cn/chat/orderlist?user_id=' + userInfo.id)
  const { code, result, msg } = res.data
  console.log(code, result, msg)
  if (code == 200) {
    tabsList.value = []
    tabsList.value.push({ title: '采购', list: result.buyGoods },)
    tabsList.value.push({ title: '卖货', list: result.sellGoods },)
    tabsList.value.push({ title: '订货', list: result.depositGoods },)
    curTab.value = tabsList.value[0].title
  } else {
    console.log(msg)
  }
}


// 计算出应该展示的列表
const showList = computed(() => {
  if (tabsList.value.length) {
    const cur = tabsList.value.find(item => {
      return curTab.value == item?.title
    })
    console.log(cur);
    return cur && cur
  } else {
    return []
  }
})

// 监听是否展示 true的话拉取最新的数据
watch(() => props.isShow, () => {
  if (props.isShow) {
    initDataList()
  }
})



// 当前选择的tabs
const curTab = ref(null)

const trigger = (id) => {
  curTab.value = id
}

return (_ctx, _cache) => {
  const _component_el_empty = _resolveComponent("el-empty")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(isDialog),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_isRef(isDialog) ? (isDialog).value = $event : null)),
    class: "popup",
    width: 550,
    title: "选择您要咨询的商品",
    onClose: _cache[1] || (_cache[1] = $event => (_ctx.emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsList.value, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            onClick: $event => (trigger(tab.title)),
            key: tab?.title,
            class: _normalizeClass(["tabs-item", curTab.value == tab.title ? 'active' : ''])
          }, _toDisplayString(tab.title) + "单 ", 11, _hoisted_2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_unref(showList).list?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(showList).list, (item, index) => {
                return (_openBlock(), _createBlock(SendGoodsItem, {
                  key: index,
                  item: item,
                  title: _unref(showList).title
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "btn btn-send",
                      onClick: $event => {_ctx.$emit('sendGoods', 1, item);}
                    }, "发送", 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1032, ["item", "title"]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              description: "暂无内容",
              "image-size": 60,
              style: {"padding":"0"}
            }))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

}