const StoreRoutes = [
  
  // ! 钢贸管家
  // {
  //   path: "/store/home",
  //   name: "StoreHome",
  //   meta: { title: "网上商城 | 首页", VTitle: "首页",alias:"S_T_O_R_E",info:{id:"",icon:""} },
  //   component: () => import("@/view/store/store-home/index.vue"),
  // },
  // {
  //   path: "/store/seckill",
  //   name: "StoreSeckill",
  //   meta: { title: "网上商城 | 特价秒杀区", VTitle: "特价秒杀区",alias:"S_T_O_R_E",info:{id:"",icon:""}  },
  //   component: () => import("@/view/store/store-seckill/index.vue"),
  // },
  // {
  //   path: "/store/deposit",
  //   name: "",
  //   meta: { title: "网上商城 | 定金锁价区" , VTitle: "定金锁价区", alias:"S_T_O_R_E",info:{id:"",icon:""} },
  //   component: () => import("@/view/store/store-deposit/index.vue"),
  // },
  // {
  //   path: "/store/contract",
  //   name: "",
  //   meta: { title: "网上商城 | 电子合同区" , VTitle: "电子合同区", alias:"S_T_O_R_E",info:{id:"",icon:""}  },
  //   component: () => import("@/view/store/store-contract/index.vue"),
  // },

  // {
  //   path: "/store/about",
  //   name: "",
  //   meta: { title: "网上商城 | 关于我们" , VTitle: "关于我们",alias:"S_T_O_R_E",info:{id:"",icon:""}  },
  //   component: () => import("@/view/store/store-about/index.vue"),
  // },
];

export default StoreRoutes