import http from "./http"

// 新增库存
export const addedGoods = (data) => http({ method: "post", url: "/steward/inventory/add", param: data })
// 钢材分类
export const $apiTypesList = (data) => http({ method: 'get', url: '/total/types/list', params: data })    
// 在售列表
export const $apiGoodsList = (data) => http({ method: "post", url: "/steward/goods/list", params: data })
// 添加库存
export const $apiAddGoods = (data) => http({ method: "post", url: "/steward/goods/add", params: data })
// 删除库存
export const $apiDelGoods = (data) => http({ method: "post", url: "/steward/goods/delete", params: data })
// 上架库存
export const $apiPutGoods = (data) => http({ method: "post", url: "/steward/goods/on/the/shelf", params: data })
// 下架库存
export const $apiOutGoods = (data) => http({ method: "post", url: "/steward/goods/off/the/shelf", params: data })
// 商品信息
export const $apiGoodsInfo = (data) => http({ method: "post", url: "/steward/goods/view", params: data })
// 编辑商品
export const $apiEditGoods = (data) => http({ method: "post", url: "/steward/goods/edit", params: data })



// 添加库存        
export const $apiAddInventory = (data) => http({ method: "post", url: "/steward/inventory/add", params: data })
// 上架库存 {token,id}
export const $apiPutInventory = (data) => http({ method: "post", url: "/steward/inventory/shelves", params: data })
// 删除库存  
export const $apiDelInventory = (data) => http({ method: "post", url: "/steward/inventory/delete", params: data })
// 编辑库存
export const $apiEditInventory = (data) => http({ method: "post", url: "/steward/inventory", params: data })
// 库存列表      
export const $apiInventoryList = (data) => http({ method: "post", url: "/steward/inventory/list", params: data })
// 库存信息
export const $apiInventoryInfo = (data) => http({ method: "post", url: "/steward/inventory/info", params: data })





