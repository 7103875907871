
const OtherRoutes = [
    {
        path: "/app",
        component: () => import("@/view/user/index.vue"),
        children: [
            {
                path: "/app/account",
                name: "AppAccount", //登录页
                component: () => import("@/view/user/account/app.account.vue"),
            },
        ]
    },
    {
        path: "/login",
        name: "Login", //登录页
        meta: {
            title: '登录',
            socket: true,
        },
        component: () => import("@/view/login/Login.vue"),
    },
    {
        path: "/bind/phone",
        name: "BindPhone",
        meta: { socket: true },
        component: () => import('@/view/login/bindPhone.vue')
    },
    {
        path: "/deal/about",
        name: "About",//关于电子交易中心
        meta: { title: '关于电子交易中心', socket: true, },
        component: () => import("@/view/deal/about/NewAbout.vue"),
    },
    {
        path: "/deal/reward/million",
        name: "Reward",//百万奖励计划
        meta: { title: '百万奖励计划' },
        component: () => import("@/view/deal/reward/MillionReward.vue"),
    },
    {
        path: "/deal/car",
        name: "ShopCar",//购物车
        meta: { title: '购物车' },
        component: () => import("@/view/deal/orderSteps/ShopCarSelect.vue"),
        // ShopCarSelect  ShopCar
    },
    {
        path: "/deal/order",
        name: "Order",//确认订单
        meta: { title: '确认订单' },
        component: () => import("@/view/deal/orderSteps/Order.vue"),
    },
    {
        path: "/deal/payment",
        name: "Payment",//去付款
        meta: { title: '支付' },
        component: () => import("@/view/deal/orderSteps/Payment.vue"),
    },
    {
        path: "/pay/success",
        name: "PaySuccess", //支付成功
        meta: { title: "支付成功" },
        component: () => import("@/view/deal/orderSteps/PaySuccess.vue"),
    },
    {
        path: "/website",
        name: "Website",
        meta: { title: '企业官网', socket: true },
        component: () => import("@/view/help/buildWebsite/index.vue"),
    },
    {
        path: "/welfare",
        name: "DealWelfare",//活动详情
        meta: { title: '活动详情' },
        component: () => import("@/view/deal/reward/index.vue"),
    },
    {
        path: "/deal/enterprise",
        name: "DealEnterprise", //企业入驻
        meta: { title: "企业入驻",socket: true  },
        component: () => import("@/view/deal/enterprise/index.vue"),
    },
    {
        path: "/receipt",
        name: "Receipt",//电子回单
        meta: { title: '电子回单', socket: true },
        component: () => import("@/view/deal/receipt/index.vue"),
    },
    {
        path: "/lzb",
        name: "Lzb",//来账宝
        meta: { title: '来账宝' },
        component: () => import("@/view/deal/lzb/index.vue"),
    },
    {
        path: "/test",
        name: "Test",//测试
        meta: { title: '测试' },
        component: () => import("@/view/passwordTest/test.vue"),
    },
    {
        path: "/test/pwd",
        name: "TestPassword",//密码控件测试
        meta: { title: '密码控件测试' },
        component: () => import("@/view/passwordTest/pwd.vue"),
    },
    {
        path: "/404",
        name: "Error",
        meta: { socket: true },
        component: () => import('@/view/404/index.vue'),
        beforeEnter(to, from, next) {
          const token = localStorage.getItem("token")
          const sales = process.env.VUE_APP_SALES
          if (token&&eval(sales)) {
            next('/sales/404')
          }else{
            next()
          }
        }
    },
];

export default OtherRoutes
