const PaymentRoutes = [
  //router: 钢付宝 
  { 
    path: "/payment",
    redirect: "/payment/order/buy",
  },
  //router: 钢付宝-页面
  { 
    path: "/payment",
    name: "SteelPayment",
    title:"钢付宝",
    component: () => import("@/view/payment/index.vue"),
    children: [
      //=> 买家订单
      { 
        path: "/payment/order/buy",
        title: "买家订单",
        component: () => import("@/view/payment/payment-orderBuy/index.vue"),
        meta: {
          icon:"&#xe751;",
          title: "钢付宝 | 买家订单",
          background: require("@/assets/payment/nav1.png"),
        },
      },
      //=> 卖家订单
      { 
        path: "/payment/order/sell",
        title: "卖家订单",
        component: () => import("@/view/payment/payment-orderSell/index.vue"),
        meta: {
          icon:"&#xe751;",
          title: "钢付宝 | 卖家订单",
          background: require("@/assets/payment/nav2.png"),
        },
      },
      //=> 合同管理
      { 
        path: "/payment/bill",
        title: "资金流水",
        component: () => import("@/view/payment/payment-bill/index.vue"),
        meta: {
          icon:"&#xe776;",
          title: "钢付宝 | 资金流水",
          background: require("@/assets/payment/nav3.png"),
        },
      },
      //=> 合同管理
      { 
        path: "/payment/contract",
        title: "合同管理",
        component: () => import("@/view/payment/payment-contract/index.vue"),
        meta: {
          icon:"&#xe75a;",
          title: "钢付宝 | 合同管理",
          background: require("@/assets/payment/nav4.png"),
        },
      },
      //=> 充值提现
      { 
        path: "/payment/wallet",
        title: "充值提现",
        component: () => import("@/view/payment/payment-wallet/index.vue"),
        meta: {
          icon:"&#xe7a0;",
          title: "钢付宝 | 充值提现",
          background: require("@/assets/payment/nav5.png"),
        },
      },
      //=> 保证金
      { 
        path: "/payment/security",
        title: "保证金",
        component: () => import("@/view/payment/payment-security/index.vue"),
        meta: {
          icon:"&#xe7cb;",
          title: "钢付宝 | 保证金",
          background: require("@/assets/payment/nav6.png"),
        },
      },
    ]
  },
  //router: 钢付宝 - 订单详情
  {
    path: "/payment/order/details",
    name: "OrderDetails",
    component: () => import("../../view/payment/order-details/index.vue"),
    meta: { title: "订单详情", }
  },
  //router: 钢付宝 - 合同详情
  {
    path: "/payment/contract/details",
    name: "ContractDetails",
    component: () => import("../../view/payment/payment-contract/details.vue"),
    meta: { title: "钢付宝 | 合同详情", }
  },
  {
    path: "/seller/contract/details",
    name: "SellerContractDetails",
    component: () => import("../../view/payment/payment-contract/seller-details.vue"),
    meta: { title: "钢付宝 | 卖家合同详情", }
  },
  //router: 钢付宝 - 保障金详情
  {
    path: "/payment/security/details",
    name: "SecurityDetails",
    component: () => import("../../view/payment/payment-security/details.vue"),
    meta: { title: "钢付宝 | 保障金详情", }
  },
  //router: 钢付宝 - 资金流水详情
  {
    path: "/payment/bill/details",
    name: "BillDetails",
    component: () => import("../../view/payment/payment-bill/details.vue"),
    meta: { title: "钢付宝 | 资金流水详情", }
  },
  //router: 钢付宝 - 交易详情
  {
    path: "/payment/wallet/details",
    name: "WalletDetails",
    component: () => import("../../view/payment/payment-wallet/details.vue"),
    meta: { title: "钢付宝 | 交易详情", }
  },
];

export default PaymentRoutes