const StewardRoutes = [
  // 钢贸管家重定向
  {
    path: "/steward",
    redirect: "/steward/inventory",
  },
  { // 钢贸管家
    path: "/steward",
    name: "SteelSteward",
    title:"钢贸管家",
    component: () => import("@/view/steward/index.vue"),
    children: [
      { // 钢贸管家
        path: "/steward/inventory",
        title: "在线库存",
        component: () => import("@/view/steward/steward-inventory/index.vue"),
        meta: {
          title: "钢贸管家 | 在线库存",
          background: require("@/assets/steward/nav1.png"),
        },
      },
      { // 在售管理
        path: "/steward/goods",
        title: "在售管理",
        component: () => import("@/view/steward/steward-goods/index.vue"),
        meta: {
          title: "钢贸管家 | 在售管理",
          background: require("@/assets/steward/nav2.png"),
        },
      },
      { // 钢贸管家
        path: "/steward/user",
        title: "客户管理",
        component: () => import("@/view/steward/steward-user/index.vue"),
        meta: {
          title: "钢贸管家 | 客户管理",
          background: require("@/assets/steward/nav3.png"),
        },
      },
      { // 钢贸管家
        path: "/steward/staff",
        title: "员工管理",
        component: () => import("@/view/steward/steward-staff/index.vue"),
        meta: {
          title: "钢贸管家 | 员工管理",
          background: require("@/assets/steward/nav4.png"),
        },
      },
    ]
  },
  {
    path: "/steward/newly",
    name: "StewardNewly",
    component: () => import("@/view/steward/steward-newly/index.vue"),
  },
  { // 钢贸管家
    path: "/steward/staff",
    title: "员工管理",
    component: () => import("@/view/steward/steward-staff/index.vue"),
    meta: {
      title: "钢贸管家 | 员工管理",
      background: require("@/assets/steward/nav4.png"),
    },
  },
];

export default StewardRoutes